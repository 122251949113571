$(function () {
    var navToggle = $('#nav-toggle');
    var navbar = $('.navbar');
    var navLink = $('.navbar li > a');
    var navOpenBtn = $('.nav-open');
    var navCloseBtn = $('.nav-close');

    // Add classes on page load

    navToggle.addClass('-visible');
    navbar.addClass('-js-navbar');
    $('.header-logo').addClass('-js-header-logo');
    $('.header-bottom .flex-wrap').addClass('-js-flex-wrap');

    // Toggle nav

    var navOff = true;
    navToggle.on('click', function (e) {
        e.preventDefault();
        if (navOff) {
            navOff = false;

            navbar.slideDown('fast', function () {
                $(this).addClass('-visible');
            });

            navOpenBtn.addClass('-hidden');

            navCloseBtn.addClass('-visible');

            $('.header-top').slideDown('fast', function () {
                $(this).addClass('-visible');
            });

            // Reset nav state when link is clicked
            navLink.on('click', function () {
                navOff = true;

                navbar.slideUp('fast', function () {
                    $(this).removeClass('-visible');
                });

                navOpenBtn.removeClass('-hidden');

                navCloseBtn.removeClass('-visible');

                $('.header-top').slideUp('fast', function () {
                    $(this).removeClass('-visible');
                });
            });
        } else {
            navOff = true;

            navbar.slideUp('fast', function () {
                $(this).removeClass('-visible');
            });

            navOpenBtn.removeClass('-hidden');

            navCloseBtn.removeClass('-visible');

            $('html, body').removeClass('-disable-scroll');

            $('.header-top').slideUp('fast', function () {
                $(this).removeClass('-visible');
            });

            $(this).css('border', '1px solid #5e398a');
        }
    });

});
