$(function () {

    var pageHeader = $('.page-header');
    var headerTop = $('.page-header .header-top');

    pageHeader.addClass('-js-page-header');
    $('html, body').addClass('-fixed-header-top-padding');

    $(".link-to-section-1").on('click', function (e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: $('#section-1 h3').offset().top - pageHeader.height()
        }, 750);
    });

    $(".link-to-section-2").on('click', function (e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: $('#section-2 h3').offset().top - pageHeader.height()
        }, 750);
    });

    $(".link-to-section-3").on('click', function (e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: $('#section-3 h3').offset().top - pageHeader.height()
        }, 750);
    });

    $(".link-to-section-4").on('click', function (e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: $('#section-4 h3').offset().top - pageHeader.height()
        }, 750);
    });

    $(".link-to-section-5").on('click', function (e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: $('#section-5 h3').offset().top - pageHeader.height()
        }, 750);
    });

    $(".link-to-section-6").on('click', function (e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: $('#section-6 h2').offset().top - pageHeader.height()
        }, 750);
    });

    $('.header-logo').on('click', function () {
        $('html, body').animate({
            scrollTop: 0
        }, 750);
    });
});
